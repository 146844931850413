<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        :basic-data="basicData"
        title="Niche Category List"
        icon="mdi-code-tags"
        addButtonText="Niche Category"
        stateendpoint="nicheCategory.nicheCategories"
        :actions="actions"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import NicheCategoryPopup from "../components/popups/NicheCategoryPopup";

export default {
  name: 'PostTypesList',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Niche Categories | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, NicheCategoryPopup},
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'name'},
        {value: 'isActive'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadNicheCategories',
        create: 'storeNicheCategory',
        update: 'updateNicheCategory',
        remove: 'removeNicheCategory',
        destroy: 'destroyNicheCategory'
      },
      basicData: {
        isActive: true
      }
    }
  },
  computed: {
    popupComponent() {
      return NicheCategoryPopup;
    }
  }
}
</script>
