<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-container>
        <v-layout>
          <v-flex xs12>
            <v-text-field
                v-model="currentItem.name"
                :rules="requiredFieldRule"
                label="Name"
                prepend-icon="create"
                required
            />
            <v-layout class="custom-switch-btn" row>
              <v-flex xs3>
                <v-subheader class="switch-btn-title">Status</v-subheader>
              </v-flex>
              <v-flex xs8>
                <v-switch
                    v-model="currentItem.isActive"
                    color="primary"
                    dense
                    inset
                    prefix="Status">
                </v-switch>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";

export default {
  name: 'NicheCategoryPopup',
  components: {BasePopup},
  mixins: [PopupMixin],
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Niche Category'
          : 'Add Niche Category'
    }
  }
}
</script>
